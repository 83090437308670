import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import sanityClient from '../sanityClient';
import { PortableText } from '@portabletext/react';
import imageUrlBuilder from '@sanity/image-url';
import InlineLoading from './InlineLoading';
import './FullPost.css';
import { Helmet } from 'react-helmet-async';

// Initialize the image URL builder
const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function FullPost() {
  const [post, setPost] = useState(null);
  const [showBackButton, setShowBackButton] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(20);
  const threshold = 5;
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    sanityClient.fetch(
      `*[_type == "post" && slug.current == $slug][0]{
        ...,
        body,
        mainImage,
        publishedAt
      }`, 
      { slug }
    )
    .then((data) => setPost(data))
    .catch(console.error);
  }, [slug]);

  const goBack = () => {
    if (location.key === 'default') {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (lastScrollY - window.scrollY > threshold) {
        setShowBackButton(true);
      } else if (window.scrollY - lastScrollY > threshold) {
        setShowBackButton(false);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  if (!post) return <InlineLoading />;

  const formatDate = (dateString) => {
    if (!dateString) return 'Date not available';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  };

  const components = {
    types: {
      image: ({value}) => {
        if (!value?.asset?._ref) {
          return null;
        }
        return (
          <img
            src={urlFor(value)
              .width(2000)
              .quality(95)
              .auto('format')
              .url()}
            alt={value.alt || ' '}
            loading="lazy"
            className="inline-image"
            style={{
              display: 'block',
              marginBottom: '10px',
              maxWidth: '100%',
            }}
          />
        );
      },
    },
    marks: {
      link: ({value, children}) => {
        const href = value?.href || '#';
        const isExternal = href.startsWith('http') || href.startsWith('https');
        return (
          <a 
            href={href}
            target={isExternal ? "_blank" : undefined}
            rel={isExternal ? "noopener noreferrer" : undefined}
          >
            {children}
          </a>
        );
      },
    },
  };

  const metaDescription = post.body 
  ? post.body[0].children[0].text.substring(0, 160) + '...'
  : 'Read this interesting post on Sam Rafie\'s blog.';

  return (
    <>
      <Helmet>
        <title>{post.title} | Sam Rafie</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={`${post.title} | Sam Rafie`} />
        <meta property="og:description" content={metaDescription} />
        {post.mainImage && (
          <meta property="og:image" content={urlFor(post.mainImage).width(1200).url()} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${post.title} | Sam Rafie`} />
        <meta name="twitter:description" content={metaDescription} />
        {post.mainImage && (
          <meta name="twitter:image" content={urlFor(post.mainImage).width(1200).url()} />
        )}
      </Helmet>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-8">
            <button 
              onClick={goBack} 
              className={`back-button ${showBackButton ? 'visible' : ''}`}
            ></button>
            <div className="full-post">
              {post.mainImage && (
                <img 
                  className="full-post-image img-fluid"
                  src={urlFor(post.mainImage).url()}
                  alt={post.title} 
                />
              )}
              <div className='title'>{post.title}</div>
              <div className='publication-date'>Published on {formatDate(post.publishedAt)}</div>
              <PortableText
                value={post.body}
                components={components}
              />
              <button onClick={goBack} className="bottom-back-button">
                &lt;--
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FullPost;
