import React, { useState, useEffect } from 'react';
import sanityClient from './sanityClient';
import { Route, Routes, Link, ScrollRestoration } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PostSummary from './components/PostSummary';
import FullPost from './components/FullPost';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { motion } from 'framer-motion';
import InlineLoading from './components/InlineLoading';

function App() {
  const [posts, setPosts] = useState([]);
  const [name] = useState("Sam Rafie");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    blog: true,
    project: true,
    profile: true,
  });

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] 
          | order(!defined(displayOrder), displayOrder asc, publishedAt desc)`
      )
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleFilterChange = (category) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [category]: !prevFilters[category],
    }));
  };

  const filteredPosts = posts.filter(post => filters[post.category]);

  return (
    <HelmetProvider>
      <ScrollRestoration />
      <Helmet>
        <title>Sam Rafie</title>
        <meta name="description" content="Personal website showcasing my profile, projects and blog." />
        <meta name="keywords" content="React, Blog, Projects, Profile" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"></link>
      </Helmet>
      <div className="App container mt-5">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <motion.h1 
            className="text-center mb-4 display-1"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            {name}
          </motion.h1>
        </Link>

        <motion.h1 
          className="text-center mb-4 display-1"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          ...
        </motion.h1>
        <Routes>
          <Route path="/" element={
            <>
              <motion.div 
                className="d-flex justify-content-center mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1, duration: 0.5 }}
              >
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="profileFilter"
                    checked={filters.profile}
                    onChange={() => handleFilterChange('profile')}
                  />
                  <label className="form-check-label" htmlFor="profileFilter" style={{color: '#6c757d'}}>Profile</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="projectFilter"
                    checked={filters.project}
                    onChange={() => handleFilterChange('project')}
                  />
                  <label className="form-check-label" htmlFor="projectFilter" style={{color: '#007bff'}}>Projects</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="blogFilter"
                    checked={filters.blog}
                    onChange={() => handleFilterChange('blog')}
                  />
                  <label className="form-check-label" htmlFor="blogFilter" style={{color: '#17a2b8'}}>Blog</label>
                </div>
              </motion.div>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <InlineLoading />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center flex-wrap">
                    {filteredPosts.map((post) => (
                      <PostSummary key={post._id} post={post} />
                    ))}
                  </div>
                  {Object.values(filters).some(filter => filter) && (
                    <div className="d-flex justify-content-center mb-4">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="profileFilter"
                          checked={filters.profile}
                          onChange={() => handleFilterChange('profile')}
                        />
                        <label className="form-check-label" htmlFor="profileFilter" style={{color: '#6c757d'}}>Profile</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="projectFilter"
                          checked={filters.project}
                          onChange={() => handleFilterChange('project')}
                        />
                        <label className="form-check-label" htmlFor="projectFilter" style={{color: '#007bff'}}>Projects</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="blogFilter"
                          checked={filters.blog}
                          onChange={() => handleFilterChange('blog')}
                        />
                        <label className="form-check-label" htmlFor="blogFilter" style={{color: '#17a2b8'}}>Blog</label>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          }/>
          <Route path="/post/:slug" element={<FullPost />} />
        </Routes>
      </div>
    </HelmetProvider>
  );
}

export default App;
