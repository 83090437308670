import React from 'react';
import './InlineLoading.css';

function InlineLoading() {
  return (
    <div className="inline-loader">
      <span className="dot">•</span>
      <span className="dot">•</span>
      <span className="dot">•</span>
    </div>
  );
}

export default InlineLoading;