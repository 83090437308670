import React from 'react';
import { Link } from 'react-router-dom';
import { truncateText, urlFor } from '../utils';
import './PostSummary.css';

function PostSummary({ post }) {
  const maxLength = 200;

  const renderText = (post) => {
    if (post.description) {
      return truncateText(post.description, maxLength);
    }
    if (post.body && post.body.length > 0) {
      const text = post.body.map(block => block._type === 'block' ? block.children[0].text : '').join(' ');
      return truncateText(text, maxLength);
    }
    return '';
  };

  const getCategoryClass = (category) => {
    switch (category) {
      case 'project':
        return 'post-project';
      case 'profile':
        return 'post-profile';
      default:
        return 'post-blog';
    }
  };

  const getCategoryIndicator = (category) => {
    switch (category) {
      case 'project':
        return <span className="badge badge-primary">Project</span>;
      case 'profile':
        return <span className="badge badge-secondary">Profile</span>;
      default:
        return <span className="badge badge-info">Blog</span>;
    }
  };

  return (
    <div className={`post-summary col-md-6 col-lg-4 mb-4 ${getCategoryClass(post.category)}`}>
      <Link to={`/post/${post.slug.current}`}>
        <div className="card">
          {post.mainImage && (
            <img 
              className="post-summary-image"
              src={urlFor(post.mainImage).url()}
              alt={post.title}
            />
          )}
          <div className="card-body">
            {getCategoryIndicator(post.category)}
            <h5 className="card-title mt-2">{post.title}</h5>
            <div className="card-text">{renderText(post)}</div>
            <Link to={`/post/${post.slug.current}`} className="btn btn-link p-0">Read more</Link>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PostSummary;
