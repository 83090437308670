import imageUrlBuilder from '@sanity/image-url';
import sanityClient from './sanityClient';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function splitTextByImageUrls(text) {
  const urlPattern = /(\bhttps?:\/\/\S+\.(?:jpg|jpeg|gif|png)\b)/g;
  const parts = text.split(urlPattern);
  return parts.map(part => {
    if (part.match(urlPattern)) {
      return { type: 'image', content: part };
    } else {
      return { type: 'text', content: part };
    }
  });
}

function truncateText(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

export { truncateText, urlFor, splitTextByImageUrls };
